<template>
  <div @click.stop="cancelActive">
    <admin-title></admin-title>
    <div class="container">
      <div class="page-title">
        <span>エントリー管理</span>
        <el-select multiple class="select top-select" @change="selectTeacher()" v-model="detail.compUserIds" placeholder="" :disabled="detail.status == -1">
          <el-option v-for="(item2, index2) in selUsers" :key="index2" :label="item2.kbName" :value="item2.id + ''"></el-option>
        </el-select>
      </div>

      <div class="content">
        <div class="left">
          <div class="head">
            <img @click="changeFlg(0)" v-if="detail.saveFlg == 1" class="head-star" src="../../assets/images/entryLevel/head_star.png">
            <img @click="changeFlg(1)" v-else class="head-star" src="../../assets/images/entryLevel/head_grey.png">
<!--            <img class="head-star" src="../../assets/images/entryLevel/head_star.png">-->
<!--            <img class="head-img" :src="detail.imageUrl">-->

            <img class="head-img" v-if="detail.imageUrl" :src="detail.imageUrl"/>
            <img class="head-img" v-else src='../../assets/images/default_head.png'/>
          </div>

          <div>
            <div class="menu-evt-pc">
              ■最終ログイン<br/>
              {{detail.lastLoginDate}}<br/>
              ■ユーザー情報最終変更<br/>
              {{detail.lastUpdateDate}}
            </div>
          </div>

          <div class="label">
            <span class="label-title">＜ 希望業種 ＞</span>
            <span class="label-desc">{{detail.expectedIndustry}}</span>
          </div>

          <div class="label">
            <span class="label-title">＜ 希望職種 ＞</span>
            <span class="label-desc">{{detail.expectedOccupation}}</span>
          </div>

          <div class="label">
            <span class="label-title">＜ 希望勤務地 ＞</span>
            <span class="label-desc">{{ detail.expectedWorkplace }}</span>
          </div>

          <div class="label">
            <span class="label-title">＜ 希望年収 ＞</span>
            <span class="label-desc">{{ detail.expectedAnnualSalaryMin }}〜{{ detail.expectedAnnualSalaryMax }}万円</span>
          </div>

        </div>
        <div class="right">
          <div class="detail">
            <div class="detail-top">
              <div class="right-top">
                <div class="top-left-new" v-if="detail.entryType==1">
                  <div style="display: flex; border-bottom: 0.0585938rem solid #9E9E9E;">
                    <p>説明会エントリー年月日</p>
                    <span style="border-right: 0.078125rem solid #9E9E9E;width: 8.75rem;text-align: center;">{{detail.entryDate}}</span>
                    <p>エントリーした説明会日時</p>
                    <span>{{detail.startDate}}</span>
                  </div>
                  <div style="display: flex;">
                    <p style="background-color: #FCDADA;">説明会タイトル</p>
                    <el-tooltip :visible-arrow="false" class="input-label" style="width: 40.75rem;" effect="dark" :content="detail.entryTitle" placement="top">
                      <span @click="navToUrl" class="ellipsis" style="text-align: left;padding-left: 1.25rem;">{{detail.entryTitle}}</span>
                    </el-tooltip>
                  </div>
                </div>
                <div class="top-left" v-else>
                  <p>エントリー年月日</p>
                  <span>{{detail.entryDate}}</span>
                  <p>エントリー原稿</p>
                  <el-tooltip :visible-arrow="false" class="input-label" effect="dark" :content="detail.entryTitle" placement="top">
                    <span @click="navToUrl" class="ellipsis">{{detail.entryTitle}}</span>
                  </el-tooltip>
                </div>

<!--                @change="selectTeacher(item)" v-model="item.compUserIds"-->
<!--                <el-option v-for="(item2, index2) in selUsers" :key="index2" :label="item2.kbName" :value="item2.id + ''"></el-option>-->
                <el-select @change="selectFlowNm" class="select select-flow" placeholder="" style="margin-bottom: 2.5rem;" v-model="processId" :disabled="detail.status == -1" v-if="detail.entryType==1">
                  <el-option v-for="(item2) in flowNmSelList" :value="item2.id" :label="item2.flowName" :key="item2.id"></el-option>
                </el-select>
                <el-select @change="selectFlowNm" class="select select-flow" placeholder="" v-model="processId" :disabled="detail.status == -1" v-else>
                  <el-option v-for="(item2) in flowNmSelList" :value="item2.id" :label="item2.flowName" :key="item2.id"></el-option>
                </el-select>

                <el-select class="select select-type" placeholder="" v-model="statusId" :disabled="detail.status == -1">
                  <el-option v-for="(item2) in flowStatusList" @click.native="selectStatus(item2)" :value="item2.id" :label="item2.kbName" :key="item2.id"></el-option>
                </el-select>
                <el-date-picker

                    ref="start"
                    v-model="value1"
                    type="datetime"
                    popper-class="no-atTheMoment"
                    value-format="yyyy-MM-dd HH:mm"
                    format="yyyy-MM-dd HH:mm"
                    class="date-select"
                >
                </el-date-picker>

<!--                <div class="export">-->
<!--                  <div @click.stop="changeExportActive()" class="export-title">-->
<!--                    <span>面 接</span>-->
<!--                    <img src="../../assets/images/entryLevel/arrow_down.png">-->
<!--                  </div>-->
<!--                  <div @click.stop="changeExportActive" :class="exportActive ? 'active' : ''" class="export-list">-->
<!--                    <p>説明会エントリー</p>-->
<!--                    <p>エントリー</p>-->
<!--                    <p>面 接</p>-->
<!--                    <p>内 定</p>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
            <div class="detail-bottom">
              <div class="bottom-left">
                <div class="left-button" style="background: #FF8A00;"  v-if="detail.isScout == 1">
                  <img src="../../assets/images/entryLevel/qi.png">
                  <span>スカウト</span>
                  <div class="mask">
                    <div>
                      <p>■送信日時</p>
                      <p>{{detail.entryDate}}</p>
                    </div>
                    <div>
                      <p>■保存リスト名</p>
                      <p>{{detail.saveListName}}</p>
                    </div>
                    <div>
                      <p>■テンプレ名</p>
                      <p>{{detail.tempName}}</p>
                    </div>
                    <div>
                      <p>■セット原稿</p>
                      <p>{{detail.entryTitle}}</p>
                    </div>
                  </div>
                </div>
                <div class="left-button" v-else>
                  <img src="../../assets/images/entryLevel/qi.png">
                  <span>スカウト</span>
                </div>
                <p class="left-id">学生ID：{{detail.userId}}</p>
                <p class="left-desc">{{detail.nameRoma}}</p>
                <p class="left-name">{{detail.name}}</p>
                <p class="left-name" v-if="detail.status == -1">【退会申請中】</p>
              </div>
              <div class="bottom-right">
                <div class="item">
                  <p>生年月日</p>
                  <span>{{detail.birthday}}</span>
                </div>
                <div class="item">
                  <p>性　別</p>
                  <span>{{detail.genderName}}</span>
                </div>
                <div class="item">
                  <p>現住所</p>
                  <span>〒{{detail.postCode}}<br/>{{detail.prefectureName}}{{detail.addressCity}}{{detail.addressStreet}}{{detail.addressBuilding}}</span>
                </div>
                <div class="item">
                  <p>電話番号</p>
                  <span>{{detail.mobile}}</span>
                </div>
                <div class="item">
                  <p>メール</p>
                  <span>{{detail.mail}}</span>
                </div>
                <div class="item">
                  <p>学校名</p>
                  <span>{{detail.schoolName}}</span>
                </div>
                <div class="item">
                  <p>学　部</p>
                  <span>{{detail.faculty}}</span>
                </div>
                <div class="item">
                  <p>学　科</p>
                  <span>{{detail.facultyDepartment}}</span>
                </div>
                <div class="item">
                  <p>コース / 専攻</p>
                  <span>{{detail.courseMajor}}</span>
                </div>
                <div class="item">
                  <p>卒業年度</p>
                  <span>{{detail.graduationYearName}}</span>
                </div>
                <div class="item">
                  <p>学　歴</p>

                  <div v-if="detail.educationList">
                    <span v-for="(item, index) in detail.educationList" :key="index">
                    {{item}}<br v-if="index != detail.educationList.length - 1"/>
                    </span>
                  </div>
                </div>
                <div class="item">
                  <p>免許 / 資格</p>
                  <div v-if="detail.qualificationList">
                    <span v-for="(item, index) in detail.qualificationList" :key="index">
                    {{item}}<br v-if="index != detail.qualificationList.length - 1"/>
                    </span>
                  </div>
                </div>

              </div>
            </div>
            <img class="arrow-left" src="../../assets/images/entryLevel/arrow_left.png">
          </div>

          <div class="process">
            <p>■ 選考履歴<i>※一次面接は「面接日」が表示されます</i></p>
            <div>
              <div class="process-list" v-for="(item2, index2) in detail.flowList" :key="index2">
                <div :style="{background: detail.flowList.length - 1 == index2 ? detail.color : ''}" class="process-item">
                  <span>{{item2.content}}</span>
                  <span>{{item2.entryDate}}</span>
                </div>
                <img v-if="!(detail.flowList.length - 1 == index2)" src="../../assets/images/entryLevel/arrow_right.png">
              </div>
            </div>

<!--            <p>■ WinC Audition参加履歴</p>-->
<!--            <div class="company-list">-->
<!--              <div class="company-item">-->
<!--                <p class="company-title">2023/04/18 東京開催</p>-->
<!--                <div class="company-bottom">-->
<!--                  <p class="matching-title">マッチング数</p>-->
<!--                  <p class="matching-num" style="color: #424242;">0<span>社</span></p>-->
<!--                  <div class="matching-detail">-->
<!--                    <span>説明会エントリー</span>-->
<!--                    <span>一</span>-->
<!--                  </div>-->
<!--                  <div class="matching-detail">-->
<!--                    <span>エントリー</span>-->
<!--                    <span>一</span>-->
<!--                  </div>-->
<!--                  <div class="matching-detail">-->
<!--                    <span>面接</span>-->
<!--                    <span>一</span>-->
<!--                  </div>-->
<!--                  <div class="matching-detail">-->
<!--                    <span>内定</span>-->
<!--                    <span>一</span>-->
<!--                  </div>-->
<!--                  <div class="matching-detail">-->
<!--                    <span>辞退</span>-->
<!--                    <span>一</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>

          <div class="right-content">
            <div class="content-top">
              <img src="../../assets/images/entryLevel/eye.png">
              <span>一緒に働きたい社長像</span>
            </div>
            <div class="content-title">{{detail.mostImportantName}}</div>
            <div class="content-bottom">{{detail.idealPresidentImage}}</div>

          </div>

          <div class="right-content">
            <div class="content-top">
              <img style="width: 1.484rem" src="../../assets/images/entryLevel/heart.png">
              <span>就職活動で企業選びの軸にしていること</span>
            </div>
            <div class="content-title">{{detail.importanceOfCompName}}</div>
            <div class="content-bottom">
              {{detail.importanceOfCompReason}}
            </div>
          </div>
          <div class="right-content">
            <div class="content-top">
              <img style="width: 1.484rem" src="../../assets/images/entryLevel/zuan.png">
              <span>学生時代に力を入れたこと</span>
            </div>
            <div class="content-title">{{detail.studentEffortName}}</div>
            <div class="content-bottom">
              {{detail.studentEffortReason}}
            </div>
          </div>

          <div class="return-button green-btn" @click="nav()">一覧へ戻る</div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
import $ from 'jquery'
export default {
  name: "entryLevelDetail",

  data() {
    return {

      exportActive: false,

      value: '',
//学生担当者
      selUsers: [],
      radio: 1,

      value1: '',

      userInfo: {},

      detail: {},

      flowNmSelList: [{}],


      processId: '',

      statusId: '',
      // status: {},

      flowStatusList: [],

      params: '',


      oldStatusId: ''
    }
  },


  mounted() {
    // console.log(this.$route.query.id)
    this.params = this.$route.query.params;

    this.userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO));
    this.gatDetail();
    this.getFlowNmSelList();

    document.body.scrollTop=document.documentElement.scrollTop=0;


  },

  methods: {



    async gatDetail() {
      // entry/detail
      let res = await this.$axios({url: `/entry/detail`, data: {id: this.$route.query.id}, method: "post"});
      if(res.code == '000') {
        if(res.data.compUserIds) {
          // console.log("ssss")
          res.data.compUserIds = res.data.compUserIds.split(',');
        } else {
          res.data.compUserIds = [];
        }

        // this.detail.formatDate = ;
        // console.log(this.detail.formatDate)
        this.detail = res.data;

        this.processId = this.detail.mainId;
        this.statusId = this.detail.detailId;

        this.oldStatusId = this.detail.detailId;
        this.flowStatusSel(this.detail.mainId);
      }



      res = await this.$axios({url: `/compUser/selUsers`, data: {compId: this.userInfo.compId}, method: "post"});
      this.selUsers = res.data;

      this.$forceUpdate();

    },

    nav() {
      this.$router.push({
        path: '/index/entryLevel',
        query: {
          params: this.params
        }
      })
    },

    navToUrl() {
      /*let url = '';
      if(this.detail.entryType == 1) {
        url =  'https://winc-career.jp/meetingDetail?id=' + this.detail.jobHuntingId + '&cid=' + this.detail.compId;
      } else if (this.detail.entryType == 3) {
        url =  'https://winc-career.jp/jobDetail?id=' + this.detail.jobHuntingId + '&cid=' + this.detail.compId;
      }

      window.open(url)*/
    },

    async getFlowNmSelList() {
      //获取主流程下拉列表
      let res = await this.$axios({url: `/entryFlow/list`, data: {token: this.userInfo.token}, method: "post"});
      if(res.code == '000') {
        this.flowNmSelList = res.data;
      }
    },

    async selectTeacher() {
      // console.log(item)
      let res = await this.$axios({url: `/entry/updCompUser`, data: {
          compUserIds: this.detail.compUserIds.join(','),
          id: this.detail.id,
          "operatorId": this.userInfo.id,
        }, method: "post"});

      if(res.code == '000') {
        this.$message({
          type: 'success',
          message: res.message
        });

      }
    },

    selectFlowNm(e) {
      // console.log(e)
      this.flowStatusSel(e);
      this.statusId = '';
      this.oldStatusId = '';
    },

    selectStatus(e) {
      this.status = e;
      if(e.kbName == '一次面接') {

        this.$refs.start.showPicker() // 手动开启时间弹窗
        this.$forceUpdate();


        let that=this;
        $(".el-picker-panel__footer").html('<button type="button" class="el-button el-picker-panel__link-btn el-button--default el-button--mini is-plain"><span>OK</span></button>')

        $(".el-picker-panel__footer .is-plain").click(function (){
          that.confirmStatus(2);
          that.$refs.start.hidePicker()
        });

        return;
      }

      this.confirmStatus(1);
      console.log(e)
    },

    confirmStatus(type) {

      let that = this;

      let data = {
        operatorId: this.userInfo.id,
        id: this.detail.id,
        userId: this.detail.userId,
        detailId: this.statusId,
        detailContent: this.status.kbName
      };
      if(type == 2) {


        //需要添加日期
        data.entryDate = this.value1;

        console.log("日期", this.value1);
         if(data.entryDate)
        {

          let dateStr=this.$moment().format("YYYY-MM-DD HH:mm");
          if(data.entryDate<=dateStr){
            that.statusId = that.oldStatusId;
            this.$confirm('一次面接は過去日を選択できません', '', {
              confirmButtonText: 'OK',
              dangerouslyUseHTMLString: true,
              showCancelButton:false,
              center: true
            }).then(() => {

            });
            return;
          }

        let week = this.$moment(this.value1).weekday();
        if (week == 0) {
          week = '日'
        } else if (week == 1) {
          week = '月'
        } else if (week == 2) {
          week = '火'
        } else if (week == 3) {
          week = '水'
        } else if (week == 4) {
          week = '木'
        } else if (week == 5) {
          week = '金'
        } else if (week == 6) {
          week = '土'
        }
        // console.log(week + 'sss')
        console.log(this.value1)
        this.$confirm(this.$moment(this.value1).format('yyyy年MM月DD日（瑞）HH:mm').replace('瑞', week) + '〜<br/>設定でよろしいですか？', '', {
          confirmButtonText: '確認',
          cancelButtonText: 'キャンセル',
          center: true,
          dangerouslyUseHTMLString: true
        }).then(() => {

          that.confirm(data);

        }).catch(() => {
          that.statusId = that.oldStatusId;
        });
      }
      } else {
        this.$confirm('ステータスを「' + this.status.kbName + '」に変更します。<br/>よろしいでしょうか？', '', {
          confirmButtonText: '確認',
          cancelButtonText: 'キャンセル',
          center: true,
          dangerouslyUseHTMLString: true
        }).then(() => {

          that.confirm(data);

        }).catch(() => {
          that.statusId = that.oldStatusId;
        });
      }
      // console.log("ss")

    },

    async confirm(data) {
      let res = await this.$axios({url: `/entry/updateFlow`, data: data, method: "post"});

      if(res.code == '000') {
        this.$message({
          type: 'success',
          message: res.message
        });

        this.gatDetail();

      }
    },

    async flowStatusSel(id) {
      if(!id) return;
      let res = await this.$axios({url: `common/flowStatusSel`, data: {
          mainId: id,
        }, method: "post"});
      if(res.code == '000') {
        this.flowStatusList = res.data;
      }

    },


    changeExportActive() {
      // console.log("sss")
      this.exportActive = !this.exportActive;
    },


    cancelActive() {
      // console.log("sss1")
      this.exportActive = false;
    },

    async changeFlg(type) {
      let res = await this.$axios({url: `/entry/favorite`, data: {
          operatorId: this.userInfo.id,
          userId: this.detail.userId,
          compId: this.detail.compId,
          saveFlg: type
        }, method: "post"});

      if(res.code == '000') {
        this.$message({
          type: 'success',
          message: res.message
        });
        this.detail.saveFlg = type;
      }
    }
  }
}
</script>

<style scoped src="../../style/entryLevelDetail.css">

</style>
